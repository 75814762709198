import { Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

const TitleFrame = styled.div`
  display: flex;
  justify-content: center;
  background-color: #eee;
  border: 1vh outset #ddd;
  border-radius: 2px;
  padding: 1vh;
  padding-bottom: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25) inset, 0 5px 10px 5px rgba(0, 0, 0, 0.25);
`

export const Title = (): ReactElement => (
  <TitleFrame>
    <Typography variant="h1" noWrap>
      THE GREATEST IMAGE
      <br />
      ON THE BLOCKCHAIN
    </Typography>
  </TitleFrame>
)
