import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'
import sha256 from 'crypto-js/sha256'
import Hex from 'crypto-js/enc-hex'
import dayjs from 'dayjs'
import { fromWei } from 'web3-utils'

interface Event {
  Bidder: string
  Price: string
  ImageUrl: string
  OnClickUrl: string
  Time: number
}

export const HistoryPage = (): ReactElement => {
  const [eventLog, setEventLog] = useState<Event[]>()

  useEffect(() => {
    fetch(process.env.REACT_APP_REST_URI + '/api/bids?from=1&to=11')
      .then((res) => res.json())
      .then((data) => {
        setEventLog(data)
      })
      .catch(console.log)
  }, [])

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" paddingBottom={2}>
      {eventLog && eventLog.length > 0 && (
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={9} md={6}>
              <TableContainer component={Paper}>
                <Typography variant="h3" align="center">
                  History
                </Typography>
                <Table aria-label="image history">
                  <TableHead>
                    <TableRow>
                      <TableCell>Price (ETH)</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventLog.map((row) => (
                      <TableRow key={row.Price}>
                        <TableCell align="right" component="th" scope="row">
                          {fromWei(row.Price)}
                        </TableCell>
                        <TableCell align="center">
                          <a href={row.OnClickUrl}>
                            <img
                              src={`${process.env.REACT_APP_REST_URI}/thumbnails/${Hex.stringify(
                                sha256(row.ImageUrl),
                              )}.jpg`}
                              alt=""
                              style={{ height: '80px' }}
                            />
                          </a>
                        </TableCell>
                        <TableCell align="right">
                          <Box>{dayjs(row.Time * 1000).format('L')}</Box>
                          <Box>{dayjs(row.Time * 1000).format('LT')}</Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  )
}
