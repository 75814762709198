import React, { ReactElement } from 'react'
import './App.css'
import { HighestBidderPage } from './pages/HighestBidderPage'
import { HistoryPage } from './pages/HistoryPage'
import { LegalDisclosurePage } from './pages/LegalDisclosurePage'
import * as dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/de'
import { HowItWorksPage } from './pages/HowItWorksPage'

const App = (): ReactElement => {
  dayjs.locale(window.navigator.language.substr(0, 2))
  dayjs.extend(localizedFormat)
  return (
    <div>
      <HighestBidderPage />
      <HowItWorksPage />
      <HistoryPage />
      <LegalDisclosurePage
        website="http://thegreatestimageontheblockchain.com"
        email="info@thegreatestimageontheblockchain.com"
      />
    </div>
  )
}

export default App
