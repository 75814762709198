/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react'
import Web3 from 'web3'
import { Mixed } from 'web3-utils'

declare global {
  interface Window {
    ethereum: any
    web3: any
  }
}

export const loginToMetaMask = async (): Promise<any> =>
  await window.ethereum.request({
    method: 'eth_requestAccounts',
  })

const useWeb3 = (): {
  bid: (
    contractAddress: string,
    price: Mixed,
    imageUrl: string,
    onClickUrl: string,
    onReceipt: () => void,
    onError: () => void,
  ) => void
} => {
  const provider = Web3.givenProvider || new Web3.providers.HttpProvider('http://127.0.0.1:7545')
  const web3 = new Web3(provider)
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const abi = require('./data/abi.json')

  const bid = useCallback(
    async (
      contractAddress: string,
      price: Mixed,
      imageUrl: string,
      onClickUrl: string,
      onReceipt: () => void,
      onError: () => void,
    ) => {
      const accounts = await window.ethereum.send('eth_requestAccounts')
      const account = accounts.result[0]
      if (!account || !contractAddress) return onError()
      const contractInstance = new web3.eth.Contract(abi, contractAddress, { from: account })
      contractInstance.options.from = account

      contractInstance.methods
        .bid(imageUrl, onClickUrl)
        .send({ from: account, gas: 1000000, value: price })
        .on('receipt', () => {
          onReceipt()
        })
        .on('error', () => {
          onError()
        })
    },
    [abi, web3.eth.Contract],
  ) // eslint-disable-line react-hooks/exhaustive-deps

  return { bid }
}

export default useWeb3
