import { Button, CircularProgress, DialogContent, TextField, TextFieldProps, Typography } from '@mui/material'
import React, { ReactElement, useRef, useState } from 'react'
import { useWeb3 } from '../helpers'
import { validUrl } from '../helpers/validUrl'
import { fromWei, toWei, toBN } from 'web3-utils'

interface Props {
  handleClose(bidPlaced: boolean): void
  contractAddress?: string
  currentPrice: string
  isTransactionPending: boolean
  setIsTransactionPending(isTransactionPending: boolean): void
}

const errorUrl = 'Please enter a valid URL.'

export const PlaceBidDialogContent = ({ handleClose, contractAddress, currentPrice }: Props): ReactElement => {
  const { bid } = useWeb3()
  const priceRef = useRef<TextFieldProps>(null)
  const imageUrlRef = useRef<TextFieldProps>(null)
  const onClickUrlRef = useRef<TextFieldProps>(null)
  const [isTransactionPending, setIsTransactionPending] = useState(false)
  const [isFormValid, setIsFormValid] = useState({
    isPriceValid: true,
    isImageUrlValid: true,
    isOnClickUrlValid: true,
  })

  const closeDialog = (bidPlaced: boolean): void => {
    setIsTransactionPending(false)
    handleClose(bidPlaced)
  }

  const currentPriceBn = toBN(currentPrice)

  const validateForm = (price: string, imageUrl: string, onClickUrl: string): boolean => {
    const isPriceValid = toBN(toWei(price)).gt(currentPriceBn)
    const isImageUrlValid = validUrl(imageUrl)
    const isOnClickUrlValid = validUrl(onClickUrl)
    setIsFormValid({ isPriceValid, isImageUrlValid, isOnClickUrlValid })
    return isPriceValid && isImageUrlValid && isOnClickUrlValid
  }

  const getMinPrice = (): string => fromWei(currentPriceBn.add(toBN(1)).toString())

  return (
    <DialogContent dividers>
      <Typography>
        Entering a higher bid will allow you to change the displayed image. In case you get outbidden
        <b> your money will be transferred back to you automatically</b>.
      </Typography>
      <TextField
        inputRef={priceRef}
        variant="outlined"
        autoFocus
        label="Price"
        helperText={
          isFormValid.isPriceValid
            ? 'Your bid in ETH.'
            : `Please enter a value greater than ${fromWei(currentPrice.toString())}.`
        }
        defaultValue={getMinPrice()}
        type="number"
        error={!isFormValid.isPriceValid}
        fullWidth
        margin="normal"
      />
      <TextField
        inputRef={imageUrlRef}
        variant="outlined"
        label="Image URL"
        helperText={isFormValid.isImageUrlValid ? 'The URL to your image.' : errorUrl}
        placeholder="http://example.com/image.png"
        error={!isFormValid.isImageUrlValid}
        fullWidth
        margin="normal"
      />
      <TextField
        inputRef={onClickUrlRef}
        variant="outlined"
        label="On Click URL"
        helperText={isFormValid.isOnClickUrlValid ? 'The URL that is called when the image was clicked.' : errorUrl}
        placeholder="http://example.com/"
        error={!isFormValid.isOnClickUrlValid}
        fullWidth
        margin="normal"
      />
      <Button
        onClick={() => {
          if (
            contractAddress &&
            typeof priceRef.current?.value === 'string' &&
            typeof imageUrlRef.current?.value === 'string' &&
            typeof onClickUrlRef.current?.value === 'string'
          ) {
            if (!validateForm(priceRef.current.value, imageUrlRef.current.value, onClickUrlRef.current.value)) return
            setIsTransactionPending(true)
            bid(
              contractAddress,
              toWei(priceRef.current.value),
              imageUrlRef.current.value,
              onClickUrlRef.current.value,
              () => closeDialog(true),
              () => setIsTransactionPending(false),
            )
          }
        }}
        disabled={isTransactionPending}
        color="primary"
        variant="contained"
        fullWidth
      >
        {isTransactionPending ? (
          <>
            {'\u00a0'}
            <CircularProgress color="secondary" size={24} />
            {'\u00a0'}
          </>
        ) : (
          'Bid'
        )}
      </Button>
    </DialogContent>
  )
}
