import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import './fonts/style.css'
import { lime } from '@mui/material/colors'

let theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: lime[50] },
  },
  typography: {
    button: {
      fontSize: '1.5rem',
      fontFamily: 'Museum Sixty',
      backgroundColor: '#565656',
      textShadow: '2px 2px 3px rgba(255,255,255,0.5)',
    },
    h2: {
      fontSize: '3.5rem',
      fontFamily: 'Museum Sixty',
    },
    h3: {
      fontFamily: 'Museum Sixty',
    },
    h4: {
      fontFamily: 'Museum Sixty',
    },
  },
})

theme = responsiveFontSizes(theme)

// h1 is excluded from responsiveFontSizes
theme.typography.h1 = {
  fontSize: 'min(5vh, 4vw)',
  fontFamily: 'Museum Sixty',
  color: 'transparent',
  backgroundColor: '#565656',
  textShadow: '2px 2px 3px rgba(255,255,255,0.5)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  lineHeight: '1.0',
  textAlign: 'center',
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
