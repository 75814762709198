import { Box, BoxProps } from '@mui/material'
import React from 'react'
import wp04 from '../images/wallpapers/04.jpg'
import wp05 from '../images/wallpapers/05.jpg'
import wp07 from '../images/wallpapers/07.jpg'
import wp08 from '../images/wallpapers/08_compressed.jpg'
import wp11 from '../images/wallpapers/11.jpg'
import wp13 from '../images/wallpapers/13.jpg'
import wp14 from '../images/wallpapers/14.jpg'
import wp22 from '../images/wallpapers/22.jpg'
import wp26 from '../images/wallpapers/26_compressed.jpg'
import wp27 from '../images/wallpapers/27.jpg'
import wp28 from '../images/wallpapers/28.jpg'
import wp29 from '../images/wallpapers/29.jpg'
import wp30 from '../images/wallpapers/30.jpg'
import wp31 from '../images/wallpapers/31.jpg'

const wallpapers = [wp04, wp05, wp07, wp08, wp11, wp13, wp14, wp22, wp26, wp27, wp28, wp29, wp30, wp31]

const Wallpaper: React.FunctionComponent<BoxProps> = ({ children, ...props }) => {
  const day = Math.floor(Date.now() / 1000 / 60 / 60 / 24)
  const wallpaperOfTheDay = day % wallpapers.length
  return (
    <Box
      {...props}
      style={{
        backgroundImage: `url(${wallpapers[wallpaperOfTheDay]})`,
        backgroundPositionX: 'center',
        backgroundSize: 'contain',
      }}
    >
      {children}
    </Box>
  )
}

export default Wallpaper
