import React, { ReactElement, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'

const before = '-2vmin'
const after = '-2.5vmin'
const framePadding = '3vmin'
const frameBorder = '5vmin'
const frameMarginHeight = '5vh'
const frameMarginWidth = '5vw'

const StyledImage = styled.img`
  border: solid 2px;
  border-bottom-color: #ffe;
  border-left-color: #eed;
  border-right-color: #eed;
  border-top-color: #ccb;
  max-height: calc(70vh - 2 * ${framePadding} - 2 * ${frameMarginHeight} - 2 * ${frameBorder});
  max-width: 100%;
`

const ImageFrame = styled.div`
  background-color: #ddc;
  border: solid ${frameBorder} #eee;
  border-bottom-color: #fff;
  border-left-color: #eee;
  border-radius: 2px;
  border-right-color: #eee;
  border-top-color: #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset, 0 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: inline-block;
  margin: ${frameMarginHeight} ${frameMarginWidth};
  padding: ${framePadding};
  position: relative;
  text-align: center;
  &:before {
    border-radius: 2px;
    bottom: ${before};
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25) inset;
    content: '';
    left: ${before};
    position: absolute;
    right: ${before};
    top: ${before};
  }
  &:after {
    border-radius: 2px;
    bottom: ${after};
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    content: '';
    left: ${after};
    position: absolute;
    right: ${after};
    top: ${after};
  }
`

interface Props {
  src: string
  setDrapesSize: (size: number) => void
}

export const GreatestImage = ({ src, setDrapesSize }: Props): ReactElement => {
  const imageFrameRef = useRef() as React.MutableRefObject<HTMLDivElement>

  useLayoutEffect(() => {
    const updatePosition = (): void => {
      const left = imageFrameRef.current?.getBoundingClientRect().left
      if (imageFrameRef) setDrapesSize(left)
    }
    window.addEventListener('resize', updatePosition)

    // timeout is workaround
    setTimeout(() => {
      updatePosition()
    }, 0)

    return () => window.removeEventListener('resize', updatePosition)
  }, [setDrapesSize])

  return (
    <ImageFrame ref={imageFrameRef}>
      <StyledImage src={src} />
    </ImageFrame>
  )
}
