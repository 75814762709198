import { Box, Link, Paper, Typography } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'
import { PlaceBidDialog } from '../components/PlaceBidDialog'
import { Button } from '@mui/material'
import { fromWei, toBN } from 'web3-utils'
import { GreatestImage } from '../components/GreatestImage'
import placeholder from '../images/placeholder.svg'
import Wallpaper from './Wallpaper'
import { Title } from '../components/Title'
import { Drapes } from '../components/Drapes'
import { isMobile } from 'react-device-detect'

export const HighestBidderPage = (): ReactElement => {
  const [highestBidder, setHighestBidder] = useState<any>()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [contractAddress, setContractAddress] = useState<string>()

  const closePlaceBidDialog = (): void => {
    setIsDialogOpen(false)
    fetchHighestBidder()
  }

  const fetchHighestBidder = (): void => {
    fetch(process.env.REACT_APP_REST_URI + '/api/bids?from=0&to=1')
      .then((res) => res.json())
      .then((data) => {
        setHighestBidder(data[0])
      })
      .catch(console.log)
  }

  const fetchContractAddress = (): void => {
    fetch(process.env.REACT_APP_REST_URI + '/api/contract')
      .then((res) => res.text())
      .then((data) => {
        setContractAddress(data)
      })
      .catch(console.log)
  }

  useEffect(() => {
    fetchHighestBidder()
    fetchContractAddress()
  }, [])

  const [drapesSize, setDrapesSize] = useState(0)

  return (
    <Wallpaper
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundImage: 'linear-gradient(#eee, #aaa)' }}
    >
      <Box maxHeight="15vh" display="flex" justifyContent="center">
        <Title />
      </Box>
      <Box maxHeight="70vh">
        {highestBidder ? (
          <Link href={highestBidder.OnClickUrl}>
            <GreatestImage src={highestBidder.ImageUrl} setDrapesSize={setDrapesSize} />
          </Link>
        ) : (
          <GreatestImage src={placeholder} setDrapesSize={setDrapesSize} />
        )}

        {!isMobile && <Drapes size={drapesSize} />}
      </Box>
      <Box maxHeight="5vh" overflow="hidden">
        {highestBidder && (
          <Paper sx={{ color: '#fff', background: 'rgba(104, 99, 99, 0.5)', backdropFilter: 'blur(2px)', px: '1rem' }}>
            <Typography noWrap style={{ fontSize: 'min(4vmin, 1.5rem)' }}>
              Currently valued at {fromWei(toBN(highestBidder.Price))} ETH
            </Typography>
          </Paper>
        )}
      </Box>
      <Box maxHeight="10vh" paddingTop="1vh" paddingBottom="1vh">
        <Button onClick={() => setIsDialogOpen(true)} variant="contained" color="primary" style={{ maxHeight: '8vh' }}>
          Place a bid
        </Button>
      </Box>
      <PlaceBidDialog
        isOpen={isDialogOpen}
        handleClose={() => closePlaceBidDialog()}
        contractAddress={contractAddress}
        currentPrice={highestBidder?.Price ? highestBidder.Price : '0'}
      />
    </Wallpaper>
  )
}
