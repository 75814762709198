import { Dialog, DialogTitle } from '@mui/material'
import React, { ReactElement, useState } from 'react'
import { SlideInTransition } from './SlideInTransition'
import { PlaceBidDialogContent } from './PlaceBidDialogContent'

interface Props {
  isOpen: boolean
  handleClose(bidPlaced: boolean): void
  contractAddress?: string
  currentPrice: string
}

export const PlaceBidDialog = ({ isOpen: open, handleClose, contractAddress, currentPrice }: Props): ReactElement => {
  const [isTransactionPending, setIsTransactionPending] = useState(false)

  const closeDialog = (bidPlaced: boolean): void => {
    setIsTransactionPending(false)
    handleClose(bidPlaced)
  }

  return (
    <Dialog onClose={() => closeDialog(false)} open={open} TransitionComponent={SlideInTransition}>
      <DialogTitle>Place a bid</DialogTitle>
      <PlaceBidDialogContent
        currentPrice={currentPrice}
        handleClose={handleClose}
        contractAddress={contractAddress}
        isTransactionPending={isTransactionPending}
        setIsTransactionPending={setIsTransactionPending}
      />
    </Dialog>
  )
}
