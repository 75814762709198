import { Box, Container, Grid, Typography } from '@mui/material'
import React, { ReactElement } from 'react'

interface Props {
  website: string
  email: string
}

export const LegalDisclosurePage = ({ website, email }: Props): ReactElement => (
  <Box
    style={{ color: 'darkgrey', backgroundColor: 'black', overflow: 'hidden', textOverflow: 'ellipsis' }}
    paddingTop={4}
    maxWidth={1}
  >
    <Container>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h5">Legal Disclosure</Typography>
          <Typography variant="h6" paragraph>
            Information in accordance with Section 5 TMG
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2">
            Metin Celik
            <br />
            Hollandstr. 68
            <br />
            44309 Dortmund
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2">
            Telephone: 004915165151665
            <br />
            E-Mail: <a href={`mailto:${email}`}>{email}</a>
            <br />
            Internet address: {website}
          </Typography>
        </Grid>
        <Grid item sm={12} md={6}>
          <Typography variant="h6" align="left">
            Accountability for content
          </Typography>
          <Typography variant="body2" align="left">
            The contents of our pages have been created with the utmost care. However, we cannot guarantee the contents'
            accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible for
            our own content on these web pages. In this matter, please note that we are not obliged to monitor the
            transmitted or saved information of third parties, or investigate circumstances pointing to illegal
            activity. Our obligations to remove or block the use of information under generally applicable laws remain
            unaffected by this as per §§ 8 to 10 of the Telemedia Act (TMG).
          </Typography>
        </Grid>
        <Grid item sm={12} md={6}>
          <Typography variant="h6" align="left">
            Accountability for links
          </Typography>
          <Typography variant="body2" align="left">
            Responsibility for the content of external links (to web pages of third parties) lies solely with the
            operators of the linked pages. No violations were evident to us at the time of linking. Should any legal
            infringement become known to us, we will remove the respective link immediately. Copyright Our web pages and
            their contents are subject to German copyright law. Unless expressly permitted by law, every form of
            utilizing, reproducing or processing works subject to copyright protection on our web pages requires the
            prior consent of the respective owner of the rights. Individual reproductions of a work are only allowed for
            private use. The materials from these pages are copyrighted and any unauthorized use may violate copyright
            laws.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Box>
)
