import React, { useState, useLayoutEffect, ReactElement, useRef } from 'react'
import styled from 'styled-components'
import drapes from '../images/drapes.png'

interface Props {
  size: number
  measureWidth: number
}

const StyledImage = styled.img<Props>`
  position: absolute;
  top: 0;
  height: 100vh;
  filter: drop-shadow(22px 0px 20px rgba(0, 0, 0, 1));
`

const LeftDrapes = styled(StyledImage)`
  right: calc(100vw - ${(props) => Math.min(props.size, props.measureWidth / 2)}px);
  transition: right 1s ease-out;
`

const RightDrapes = styled(StyledImage)`
  left: calc(100vw - ${(props) => Math.min(props.size, props.measureWidth / 2)}px);
  transition: left 1s ease-out;
  transform: scaleX(-1);
`

interface DrapesProps {
  size: number
}

export const Drapes = ({ size }: DrapesProps): ReactElement => {
  const drapesRef = useRef() as React.MutableRefObject<HTMLImageElement>
  const [measuredWidth, setMeasuredWidth] = useState(0)

  useLayoutEffect(() => {
    const updatePosition = (): void => {
      const width = drapesRef.current?.getBoundingClientRect().width
      if (drapesRef) setMeasuredWidth(width)
    }
    window.addEventListener('resize', updatePosition)

    // timeout is workaround
    setTimeout(() => {
      updatePosition()
    }, 500)

    return () => window.removeEventListener('resize', updatePosition)
  }, [])

  return (
    <>
      <LeftDrapes src={drapes} size={size} measureWidth={measuredWidth} ref={drapesRef} />
      <RightDrapes src={drapes} size={size} measureWidth={measuredWidth} />
    </>
  )
}
