import { Box, Link, Typography } from '@mui/material'
import React, { ReactElement } from 'react'

export const HowItWorksPage = (): ReactElement => (
  <Box p={4} width={1} display="flex" justifyContent="center">
    <Box maxWidth="40rem">
      <Typography variant="h3">How it works</Typography>
      <Typography variant="body1" align="left">
        This website will always show an image that is set by the highest bidding person. By placing a higher bid you
        get the right to show your image on this website. Also you have the option to set an URL that is called when
        clicking the image. In case you get outbid your money will be transferred back to your wallet. After that your
        image will still be visible in our history. This is a failsafe process that is backed by an Ethereum Smart
        Contract. To place a bid we recommend using a crypto wallet like{' '}
        <Link href="https://metamask.io/">MetaMask</Link>.
      </Typography>
    </Box>
  </Box>
)
